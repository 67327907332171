import React from 'react'
import {Store} from "../../../store";
import './Logo.scss'
import logo from '../../../assets/images/spinmatic.png'


const LogoComponent = (props) => {

    const {state } = React.useContext(Store);

    return(
           state.module.modules.logo.show && <img className='logoProp' src={logo}/>
    )


};


export default LogoComponent
