import React, { useEffect, useState } from 'react';
import GameList from '../components/terminalScreens/gameListScreen/GameList';
import ApiService from '../services/ApiService';
import { Store } from '../store';
import Cookies from 'universal-cookie';
import AliasScreen from './aliasScreen/AliasScreen'
import TerminalNameScreen from '../components/terminalNameScreen/terminalNameScreen';
import './App.scss';
import { translate } from "react-i18next";
import LoaderInverted from './shared/loader/Loader'
import Sidebar from '../components/modules/sidebar/Sidebar'
import HeaderComponent from '../components/modules/header/Header'
import DirectionButton from '../components/modules/directionButton/DirectionButton'
const terminalData = require('../assets/external/terminal_ui.json');
// require(`../assets/styles/${terminalData.theme}.scss`);
const electron = window.require('electron');
const {ipcRenderer} = electron;

const App = (props) => {
    const { state, dispatch } = React.useContext(Store);
    const [isAliasOpen, setIsAliasOpen] = useState(true);
    const [isExistUser, setIsExistUser] = useState(false);
    const [version, setVersion] = useState(0);
    const cookies = new Cookies();
    const year = new Date().getFullYear();

    const terminalID = localStorage.getItem('terminal_id');

    useEffect(() => {
        localStorage.setItem('init', JSON.stringify({}));
        checkInit();
        setSecondScreen("http://cdn.xpressgaming.net/spinmaticterminalmain.jpg");
        setInterval(() => {
            checkInit();
        }, 5000)
    }, []);

    useEffect(() => {
        require(`../assets/styles/${terminalData.theme}.scss`);
    }, []);

    useEffect(() => {
        if (ipcRenderer !== false) {
            ipcRenderer.send('version-request', "version");            
            ipcRenderer.on('version-request-reply', function (event, args){
                setVersion(args);
            });
        }
    }, [version])

    const setSecondScreen = url => {
        if (ipcRenderer != undefined && ipcRenderer != false) {
            ipcRenderer.send('secondScreen', url);
        }
    }


    const checkInit = () => {
        let initData = localStorage.getItem('init');
        const initRes = ApiService('/terminal/init', { terminal_id: terminalID });
        initRes.then(resp => {
            if (resp.status === 200) {
                cookies.set('BO_TOKEN', resp.data.token, { path: '/' });
                if (JSON.stringify(JSON.parse(initData)) !== JSON.stringify(resp.data.data)) {
                    //i18next.changeLanguage(resp.data.data.site.defaultLanguage);
                    dispatch({
                        type: 'SET_INIT_INFO',
                        payload: resp.data.data
                    });
                    dispatch({
                        type :'CHECK_MODULE',
                        payload : terminalData
                    });

                    localStorage.setItem('init', JSON.stringify(resp.data.data));
                }

                let actualResponse = resp.data.data;
                if (!actualResponse.hasOwnProperty('terminal')) {
                    setIsAliasOpen(true);
                } else {
                    setIsAliasOpen(false);
                    if (actualResponse.terminal.player_id !== '0') {
                        setIsExistUser(true);
                    } else {
                        setIsExistUser(false);
                    }
                }

            }
        })
    }

    const contents = () => {
        return (    
            <>
                <HeaderComponent myState={state} />  
                <div className="content">
                    <Sidebar myState={{state}}/>                
                    <GameList />
                    <div className={`direction-buttons ${state.module.modules.sidebar.show === true && state.module.modules.sidebar.position === 'right' ? 'direction-buttons-left' : 'direction-buttons-right'}`}>
                        <div className="direction-button-in-app">
                            <DirectionButton direction="up" />
                            <DirectionButton direction="down" />
                        </div>                        
                    </div>
                </div>                      
                <p className="version-field">Spinmatic Entertainment © {year} | v{version} {props.t('all_rights_reserved')}</p>                
            </>
        )
    }

    return (
        <div className="app-component">
            {
                isAliasOpen ?
                    <AliasScreen /> :
                    isExistUser ?
                        state.init.user !== null && state.init.user !== undefined ?
                            contents()  : <LoaderInverted /> :
                        Object.keys(state.init).length > 0 &&
                        <TerminalNameScreen />
            }
        </div>
    )
}

export default translate("translations")(App);
