import React, { useState, useEffect } from 'react'
import { Store } from "../../../store";
import Odometer from 'react-odometerjs';
import colors from '../../../assets/external/jackpot_colors';

const JackpotComponent = () => {
    const { state } = React.useContext(Store);
    const [approachingRange, setApproachingRange] = useState(0);
    const [explosionApproach, setExplosionApproach] = useState(0);
    const [explosionStatus, setExplosionStatus] = useState(false);
    const [jackpotDisabled, setJackpotDisabled] = useState(false);
    const [totalPot, setTotalPot] = useState(0);

    let { localJackpot } = state.init;

    useEffect(() => {
        if (localJackpot !== false && localJackpot !== undefined && localJackpot !== null) {

            setExplosionApproach(localJackpot['explosionApproach']);
            setTotalPot(localJackpot['totalPot']);
            setJackpotDisabled(localJackpot['jackpotDisabled']);
            setExplosionStatus(localJackpot['explosionStatus']);
            setApproachingRange(localJackpot['approachingRange']);

            changeBgColor();
            if (Number(approachingRange) >= 100) {
                addShakeFeature();
            }
        }
    }, [state, approachingRange, explosionApproach, explosionStatus, jackpotDisabled, totalPot]);

    const addShakeFeature = () => {
        let el = document.querySelector('#jackpot-module');
        el.style.animation = 'shake 0.5s';
        el.style.animationIterationCount = 'infinite';
    }

    const changeBgColor = () => {
        let el = document.querySelector('#jackpot-module');
        if (el !== null) {
            colors.map(function (color) {
                if (Number(approachingRange) >= color.rangeStart && Number(approachingRange) <= color.rangeEnd) {
                    el.style.boxShadow = '25px 0 10px 8px' + color.hexColor + ',' + '-25px 0 10px 8px' + color.hexColor;
                    el.style.backgroundImage = 'none';
                    el.style.backgroundColor = color.hexColor;
                    el.style.border = 'none';
                }
            })
        }
    };

    return (
        state.module.modules.jackpot.show && jackpotDisabled !== true && localJackpot !== false &&
        <div id="jackpot-module">
            <Odometer value={totalPot} format="(,ddd).dd" />
        </div>
    )
};

export default JackpotComponent;
