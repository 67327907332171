import React, { useState, useEffect, useContext } from 'react';
import ApiService from '../../../services/ApiService';
import { useHistory, Redirect } from 'react-router-dom';
import {Store} from '../../../store';
import LoaderInverted from '../../shared/loader/Loader'
import { translate } from "react-i18next";
import '../../../index.scss';
// let terminalData = require('../../../assets/external/terminal_ui.json');


const Login = (props) => {
    let history = useHistory();
    const {dispatch} = useContext(Store);
    const [isTicketExist, setIsTicketExist] = useState(false);    
    useEffect(() => {
        const ticketRes = ApiService('/terminal/ticket', {ticket : props.match.params.ticket_id});
        ticketRes.then(resp => {
            if (resp.status === 200) {
                dispatch({
                    type: 'SET_TICKET_INFO',
                    payload: resp.data.data
                })    
                localStorage.setItem('terminal_id', resp.data.data.terminal.id);
                setTimeout(() => {
                    setIsTicketExist(true);
                }, 2000)
            } else {
                setIsTicketExist(false);
                history.push("/");
            }
        });
    }, [])

    /*useEffect(() => {
        import(`../../../assets/styles/${terminalData.theme}.scss`).then(() => {
            console.log(terminalData);
        });
    }, [])*/

    return (
        <div>
            {
                isTicketExist ? <Redirect to="/casino"/> : 
                <LoaderInverted message={props.t('please_wait')}/>
            }
        </div>
    )
}

export default translate("translations")(Login);
