import React, {useEffect, useState, useContext} from 'react';
import ApiService from '../../services/ApiService';
import LoaderInverted from '../shared/loader/Loader';
import {useHistory} from 'react-router-dom'
import { translate, Trans } from "react-i18next";
import {Store} from '../../store';
const electron = window.require('electron');
const {ipcRenderer} = electron;

const Game = (props) => {
    let history = useHistory();
    let gameProps = "";
    if(props.location.state !== undefined) {
        gameProps = props.location.state.game;
    }
    const [isGameRespExist, setGameRespExist] = useState(false);
    const [gameInfo, setGameInfo] = useState({});
    const {state} = useContext(Store);

    useEffect(() => {
        if (Object.keys(state.init).length > 0) {
            const {id} = state.init.terminal;
            const {language} = state.init.site;

            const gameRes = ApiService('/game', {
                game_id: gameProps.id, 
                terminal_id: id, 
                language : language, 
                provider_id: ""});
            gameRes.then(resp => {
                if (resp.status === 200) {
                    setGameInfo(resp.data.data.gameData);
                    setGameRespExist(true);
                    setSecondScreen(resp.data.data.secondScreen);
                } else {
                    setGameRespExist(false);
                }
            })
        } else {
            history.push('/casino');
        }
    }, []);

    
    useEffect(() => {    
       if(ipcRenderer !== false){
           ipcRenderer.on('keyboard-event', function (event, arg) {
               if (String(arg).toLowerCase() === "h")
               {
                    history.push('/casino');
               }
            });
           ipcRenderer.on('transaction', function (event, arg) {
               spinSpinmatic('updatebalance');
           });
        }
    },[]);

    function spinSpinmatic(action) {
        let gameFrame = document.getElementById('flasher');
        if(gameFrame !== undefined && gameFrame !== null && gameFrame ) {
            let gameFrameWindow = gameFrame.contentWindow;
            gameFrameWindow.postMessage({name:action},'*');
        }
    }

    const setSecondScreen = url => {
        if (ipcRenderer !== undefined && ipcRenderer !== false) {
            ipcRenderer.send('secondScreen', url);
        }
    };

    return (
        <div>
            {
                isGameRespExist ? 
                <iframe id="flasher"
                        src={`${gameInfo.iframeUrl}&language=${sessionStorage.getItem('lang')}`}
                        style = {{height:"100vh", width: "100%", border:'0'}} 
                        allowFullScreen                         
                /> :
                <LoaderInverted message={props.t('please_wait')}/>
            }
            
        </div>
    )
}

export default translate("translations")(Game);
